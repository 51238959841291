<template>

  <div>
    <b-card title="Dziękujemy za złożenie zamówienia">
      <b-card-text>
        <b-container>
          <b-row>
            <b-col>
              <h4>Zamówienie</h4>
              <p><strong>Data zamówienia:</strong> {{ orderCreatedAt }}</p>
              <p><strong>Kwota zamówienia: </strong> {{ order.total }} zł</p>
            </b-col>
            <b-col>
              <h4>Płatność</h4>
              <p><strong>Status:</strong> {{ order.status }}</p>
              <p><strong>Kwota zamówienia brutto: </strong> {{ order.total }} zł</p>
            </b-col>
          </b-row>
        </b-container>
      </b-card-text>
    </b-card>

    <b-card title="Elementy zamówienia">
      <b-card-text>
        <b-table
          responsive="sm"
          :items="order.items"
          :fields="fields"
        />
      </b-card-text>
    </b-card>

  </div>

</template>

<script>
import {
  BCard, BCardText, BContainer, BRow, BCol, BTable,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BContainer,
    BRow,
    BCol,
    BTable,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Nazwa',
        },
        {
          key: 'quantity',
          label: 'Ilość',
        },
        {
          key: 'total',
          label: 'Cena',
          formatter: value => `${value} zł`,
        },
      ],
      orderId: '',
      order: {},
    }
  },
  computed: {
    orderCreatedAt() {
      if (typeof this.order.createdAt !== 'undefined') return new Date(this.order.createdAt).toISOString().substr(0, 10)

      return ''
    },
  },
  created() {
    this.fetchOrder(this.$route.params.orderId)
  },
  methods: {
    fetchOrder(orderId) {
      this.$http.get(`https://api.${this.$store.state.domain}/api/my/order/${orderId}`).then(response => {
        this.order = response.data.order
      })
    },
  },
}
</script>

<style>

</style>
